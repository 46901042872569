<template>
  <div :id="'vbarx' + this.facetCode">
    <div v-if="loading" class="loading">
      <span class="loading-spinner spinner-lg"></span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.loading {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #fffa;
  position: absolute;

  width: 100%;
  height: 100%;

  .spinner-grow {
    width: 2em;
    height: 2em;
  }
}
</style>

<script>
import _ from 'lodash'
import colours from '@/mixins/colours'
import navigation from '@/mixins/navigation'
import numbro from 'numbro'
import murmurhash from 'murmurhash'

import * as d3 from 'd3'

var MARGIN = { top: 20, right: 20, bottom: 90, left: 120 }
var WIDTH = 1200 - MARGIN.left - MARGIN.right
var HEIGHT = 400 - MARGIN.top - MARGIN.bottom

export default {
  name: 'BarTextVis',
  mixins: [colours, navigation],
  props: {
    facet: {
      type: Object,
      required: false,
    },
    facetCode: {
      type: String,
      required: true,
    },
    singular: {
      type: String,
      required: false,
      default: 'item',
    },
    plural: {
      type: String,
      required: false,
      default: 'items',
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    colours: {
      type: Array,
      required: false,
      default: () => [
        'blue',
        'blue-tint80',
        'light-blue',
        'light-blue-tint80',
        'purple',
        'purple-tint80',
        'yellow',
        'yellow-tint80',
        'violet',
        'violet-tint80',
        'orange',
        'orange-tint80',
        'magenta',
        'magenta-tint80',
      ],
    },
  },
  data() {
    return {
      v: [],
    }
  },
  mounted() {
    var vis = d3.select('#vbarx' + this.facetCode)

    var tooltip = vis
      .append('div')
      .attr('class', 'tooltip')
      .style('background-color', 'white')
      .style('opacity', 0)
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('padding', '5px')

    var svg = vis
      .append('svg')
      .attr('viewBox', [
        0,
        0,
        WIDTH + MARGIN.left + MARGIN.right,
        HEIGHT + MARGIN.top + MARGIN.bottom,
      ])
      .append('g')
      .attr('transform', 'translate(' + MARGIN.left + ',' + MARGIN.top + ')')

    svg
      .append('g')
      .attr('class', 'axisBottom')
      .attr('transform', 'translate(0, ' + HEIGHT + ')')

    svg.append('g').attr('class', 'axisLeft').attr('transform', 'translate(0, 0)')

    this.$_mychart = vis
    this.$_mysvg = svg
    this.$_mytooltip = tooltip

    this.updateValues(null)
  },
  watch: {
    facet(newValue) {
      this.updateValues(newValue)
    },
  },
  computed: {
    visColours() {
      return _.map(this.colours, (n) => this.colour(n))
    },
  },
  methods: {
    termChanged(termId) {
      this.routeTo(this.generateFacetLink(this.facetCode, termId))
    },
    updateValues(facet) {
      var self = this

      if (!facet) return

      // collect leaves - group is 'parent' facet displayterm
      let recurseTerms = function (terms, group) {
        if (!terms) {
          return []
        }
        _.forEach(terms, (d) => (d.group = group))
        return _.reduce(
          terms,
          (allTerms, term) =>
            allTerms.concat(
              term.subTerms.length > 0 ? [] : [term],
              recurseTerms(term.subTerms, term.displayTerm)
            ),
          []
        )
      }

      var nf = recurseTerms(facet.terms, null)

      var vals = nf.map((x) => {
        return {
          count: x.count,
          size: Math.sqrt(x.count),
          text: x.group ? x.group + ': ' + x.displayTerm : x.displayTerm,
          term: x.term,
          group: x.group,
        }
      })

      this.v = vals // _.sortBy(vals, 'count').reverse();

      // annoyingly the mouseXX event handlers break the transitions, so block them for a time.
      if (!self.$_animating) {
        self.$_animating = true
        setTimeout(() => {
          self.$_animating = false
        }, 2000)
      }

      let mouseOver = function () {
        if (self.$_animating) return

        self.$_mytooltip.transition().duration(100).style('opacity', 0.9)

        d3.select(this).transition().duration(100).style('opacity', 0.5)
      }

      let mouseLeave = function () {
        if (self.$_animating) return

        self.$_mytooltip.transition().duration(100).style('opacity', 0)

        d3.select(this).transition().duration(100).style('opacity', 1)
      }

      let mouseMove = function (d) {
        if (self.$_animating) return

        var desc = d.count === 1 ? self.singular : self.plural
        self.$_mytooltip
          .html(numbro(d.count).format({ thousandSeparated: true }) + ' ' + desc)
          .style('left', '20px')
          .style('top', '20px')
      }

      let click = function (d) {
        self.termChanged(d.term)
      }

      // set the ranges
      var x = d3
        .scaleBand()
        .domain(this.v.map((d) => d.text))
        .rangeRound([0, WIDTH])
        .padding(0.05)

      var y = d3
        .scaleLinear()
        .domain([0, d3.max(this.v, (d) => d.size)])
        .nice()
        .range([HEIGHT, 0])

      var svg = self.$_mysvg

      var rsvg = this.$_mychart.select('svg')
      rsvg
        .select('g.axisBottom')
        .style('font', '14px helvetica')
        .call(d3.axisBottom(x))
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '.4em')
        .attr('dy', '.8em')
        .attr('transform', 'rotate(-15)')

      // draw
      var rects = svg.selectAll('rect').data(this.v)

      rects.join(
        (enter) =>
          enter
            .append('rect')
            .attr('x', (d) => x(d.text))
            .attr('y', (d) => y(d.size))
            .attr('height', (d) => y(0) - y(d.size))
            .attr('width', () => x.bandwidth())
            .attr('class', function () {
              return 'bar'
            })
            .attr('fill', (d) => {
              var h = murmurhash.v3(d.group || 'null') % self.visColours.length
              return self.visColours[h]
            })
            .on('click', click)
            .on('mouseover', mouseOver)
            .on('mousemove', mouseMove)
            .on('mouseleave', mouseLeave),
        (update) =>
          update
            .transition()
            .duration(1000)
            .attr('fill', (d) => {
              var h = murmurhash.v3(d.group || 'null') % self.visColours.length
              return self.visColours[h]
            })
            .attr('x', (d) => x(d.text))
            .attr('y', (d) => y(d.size))
            .attr('height', (d) => y(0) - y(d.size))
            .attr('width', () => x.bandwidth()),
        (exit) => exit.transition().duration(1000).attr('y', y(0)).attr('height', 0)
      )
    },
  },
}
</script>
