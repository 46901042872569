<template>
  <div v-if="filteredRelated" class="resources-container">
    <h2>{{ selectedRelated.headingLabel }} {{ name }}</h2>

    <div class="d-flex align-items-center resources-buttons">
      <div class="flex-grow-1">
        <b-button
          variant="primary"
          class="rounded"
          :class="selectedRelated === r ? 'inverse' : ''"
          v-for="r in filteredRelated"
          :key="r.name"
          :pressed="selectedRelated === r"
          @click="changedRelated(r)"
          >{{ r.label }} <span class="related_count">&#40;{{ r.count }}&#41;</span></b-button
        >
      </div>
      <div v-if="enableFilter" class="d-flex">
        <div class="button-container">
          <b-button variant="primary" class="rounded" @click="toggleFilter()"
            >Partners<span class="ml-2"
              ><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'caret-down' }" /></span
          ></b-button>

          <div class="arrow-container">
            <div class="arrow" v-if="showFilter"></div>
          </div>
        </div>
      </div>
    </div>

    <keep-alive>
      <PartnerFilter
        v-if="showFilter"
        :partners="selectedRelated.partners"
        @filter-updated="filterUpdated"
        @close="showFilter = false"></PartnerFilter>
    </keep-alive>

    <keep-alive>
      <component
        v-bind:is="selectedRelated.component"
        :name="name"
        :related-names="relatedNames"
        :resources="resources"
        :index="selectedRelated.index"
        :partners="selectedPartners"
        :key="refreshResults"></component>
    </keep-alive>
  </div>
</template>

<style scoped lang="scss">
$arrow-height: 20px;
$button-spacing: 1rem;

.resources-container {
  margin-top: 2rem;

  h2 {
    margin-bottom: 2rem;
  }

  .resources-buttons {
    flex-wrap: wrap;
    .d-flex {
      align-self: flex-end;
    }

    .btn-primary:not(:disabled):not(.disabled).active {
      background: colour(purple);
      box-shadow: none;
      &:hover {
        color: colour(purple);
        background: colour(white);
      }
    }
    button {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      background: colour(white);
      color: colour(purple);
      transition: 0.5s ease-in-out;
      font-weight: 600;
      outline: none;
      &:hover,
      &:active {
        background: colour(purple);
        color: colour(white);
      }
      &:focus {
        box-shadow: none;
        background: colour(purple);
        color: colour(white);
      }
      .related_count {
        font-weight: 500;
        opacity: 0.7;
        margin-left: 0.3em;
      }
    }

    button.btn-primary {
      @media (max-width: 575px) {
        height: auto;
      }
    }

    .button-container {
      button {
        margin-right: 1rem;
        background: transparent !important;
        border-color: transparent !important;
        color: colour(purple);
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      @media (max-width: 575px) {
        text-align: left;
        margin-top: 1.3rem;
      }

      &:last-child {
        button {
          margin-right: 0rem;
        }
        .arrow-container {
          margin-right: 0rem;
        }
      }

      .arrow-container {
        margin-right: 1rem;

        .arrow {
          width: 0;
          height: 0;
          border-left: 16px solid transparent;
          border-bottom: $arrow-height solid colour(grey);
          border-right: 16px solid transparent;
          margin-top: 0;
          position: relative;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
</style>

<script>
import _ from 'lodash'

import PartnerFilter from '@/components/people/PartnerFilter.vue'
import PeopleRelated from '@/components/people/PeopleRelated.vue'
import PeopleSelected from '@/components/people/PeopleSelected.vue'
import PeopleSearch from '@/components/people/PeopleSearch.vue'

import service from '@/service/service'

export default {
  name: 'ResourcesContainer',
  components: {
    PartnerFilter,
    PeopleRelated,
    PeopleSelected,
    PeopleSearch,
  },
  props: {
    person: {
      type: Object,
      required: true,
    },
    partners: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedPartners: [],
      selectedRelated: {},
      enableFilter: true,
      showFilter: false,
      refreshResults: 0,
      related: [
        {
          name: 'selected',
          label: 'Selected',
          headingLabel: 'Selected resources for',
          component: 'PeopleSelected',
          filterSupported: true,
          count: 0,
        },
        {
          name: 'related',
          label: 'Related',
          headingLabel: 'Related to',
          component: 'PeopleRelated',
          filterSupported: true,
          count: 0,
        },
        {
          name: 'by',
          label: 'Resources by',
          headingLabel: 'Resources by',
          component: 'PeopleSearch',
          index: 'exact_creator',
          filterSupported: false,
          count: null,
        },
        {
          name: 'about',
          label: 'Resources about',
          headingLabel: 'Resources about',
          component: 'PeopleSearch',
          index: 'exact_subject',
          filterSupported: false,
          count: null,
        },
      ],
    }
  },
  mounted: function () {
    this.changedRelated(this.filteredRelated[0])

    // determine how many search results are available
    _(this.related)
      .filter(['count', null])
      .forEach((r) => {
        service.search
          .queryCollection('-1', r.index + ':"' + this.name + '"', {}, 0, 0, 0)
          .then((response) => {
            r.count = response.totalRecords
          })
      })
  },
  computed: {
    name() {
      return this.person.primaryName
    },
    resources() {
      _.forEach(this.person.resources, this.assignPartner)
      return this.person.resources
    },
    relatedNames() {
      _.forEach(this.person.relatedNames, this.assignPartner)
      return this.person.relatedNames
    },
    filteredRelated() {
      var self = this
      return _(self.related)
        .filter((r) => {
          if (r.name === 'selected' && !_.isEmpty(self.resources)) {
            r.count = _(self.resources)
              .flatMap((o) => o)
              .size()
            r.partners = _(self.resources)
              .flatMap((o) => o)
              .map((o) => o.partner)
              .uniq()
              .value()
          } else if (r.name === 'related' && !_.isEmpty(self.relatedNames)) {
            r.count = _(self.relatedNames)
              .flatMap((o) => o)
              .size()
            r.partners = _(self.relatedNames)
              .flatMap((o) => o)
              .map((o) => o.partner)
              .uniq()
              .value()
          }

          return r.count == null || r.count > 0
        })
        .value()
    },
  },
  methods: {
    changedRelated(r) {
      this.selectedRelated = r
      this.selectedPartners = []
      this.enableFilter = r.filterSupported
      if (!this.enableFilter) {
        this.showFilter = false
      }
      this.refreshResults += 1
    },
    filterUpdated(selectedPartners) {
      this.selectedPartners = selectedPartners
    },
    toggleFilter() {
      this.showFilter = !this.showFilter
    },
    assignPartner(value, key) {
      let self = this
      var partners = this.person.contributors
      _.forEach(value, function (v) {
        v.partner = partners[key.toLowerCase()]
        v.thumbnailIsCulturallySensitive = self.person.culturallySensitive
        v.parentId = self.person.id
      })
    },
  },
}
</script>
