<template>
  <div :id="'vstate' + this.facetCode">
    <div v-if="loading" class="loading">
      <span class="loading-spinner spinner-lg"></span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.loading {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #fffa;
  position: absolute;

  width: 100%;
  height: 100%;

  .spinner-grow {
    width: 2em;
    height: 2em;
  }
}
</style>

<script>
import _ from 'lodash'
import colours from '@/mixins/colours'
import navigation from '@/mixins/navigation'
import numbro from 'numbro'

import * as d3 from 'd3'

export default {
  name: 'StateMapVis',
  mixins: [colours, navigation],
  props: {
    facet: {
      type: Object,
      required: false,
    },
    facetCode: {
      type: String,
      required: true,
    },
    singular: {
      type: String,
      required: false,
      default: 'item',
    },
    plural: {
      type: String,
      required: false,
      default: 'items',
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  watch: {
    facet(newValue) {
      this.updateValues(newValue)
    },
  },
  mounted() {
    const height = 400
    const width = 1200

    var vis = d3.select('#vstate' + this.facetCode)

    var tooltip = vis
      .append('div')
      .attr('class', 'tooltip')
      .style('background-color', 'white')
      .style('opacity', 0)
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('padding', '5px')

    const svg = vis.append('svg').attr('viewBox', [0, 0, width, height])

    this.$_mychart = svg

    var projection = d3
      .geoMercator()
      .scale(575)
      .center([165, -28.5])
      .translate([width / 2, height / 2])

    var self = this
    var ausMap = this.genData().values.features

    let mouseOver = function () {
      if (self.$_animating) return

      tooltip.transition().duration(100).style('opacity', 0.9)

      d3.selectAll('.Region')
        .transition()
        .duration(100)
        .style('opacity', 0.8)
        .style('stroke', '#aaa')

      d3.select(this).transition().duration(100).style('opacity', 1).style('stroke', 'black')
    }

    let mouseLeave = function () {
      if (self.$_animating) return

      tooltip.transition().duration(100).style('opacity', 0)

      d3.selectAll('.Region').transition().duration(100).style('stroke', '#aaa').style('opacity', 1)

      d3.select(this).transition().duration(100).style('stroke', '#aaa')
    }

    let mouseMove = function (d) {
      if (self.$_animating) return

      var desc = d.count === 1 ? self.singular : self.plural
      tooltip
        .html(numbro(d.count).format({ thousandSeparated: true }) + ' ' + desc)
        .style('left', '20px')
        .style('top', '20px')
    }

    let click = function (d) {
      tooltip.style('opacity', 0)
      self.termChanged(d.properties.code)
    }

    self.$_mychart
      .append('g')
      .selectAll('path')
      .data(ausMap)
      .enter()
      .append('path') // draw each region
      .attr('d', d3.geoPath().projection(projection))
      .attr('id', function (d) {
        return d.properties.code
      })
      .attr('stroke-width', function () {
        return 1
      })
      .attr('stroke', '#aaa')
      .attr('class', function () {
        return 'Region'
      })
      .on('click', click)
      .on('mouseover', mouseOver)
      .on('mousemove', mouseMove)
      .on('mouseleave', mouseLeave)

    // outline - expand, colour, blur
    var filter = self.$_mychart.append('defs').append('filter').attr('id', 'water')

    filter
      .append('feMorphology')
      .attr('result', 'morphOut')
      .attr('in', 'SourceAlpha')
      .attr('operator', 'dilate')
      .attr('radius', '5')

    filter
      .append('feColorMatrix')
      .attr('result', 'matrixOut')
      .attr('in', 'morphOut')
      .attr('type', 'matrix')
      .attr(
        'values',
        '0 0 0 0 0.5 ' + // r
          '0 0 0 0 0.7 ' + // g
          '0 0 0 0 0.9 ' + // b
          '0 0 0 1 0'
      )

    filter
      .append('feGaussianBlur')
      .attr('stdDeviation', '5')
      .attr('in', 'matrixOut')
      .attr('result', 'blurOut')

    filter
      .append('feBlend')
      .attr('in', 'SourceGraphic')
      .attr('in2', 'blurOut')
      .attr('mode', 'normal')

    d3.select('g').style('filter', 'url(#water)')
  },
  methods: {
    termChanged(termId) {
      this.routeTo(this.generateFacetLink(this.facetCode, termId))
    },
    updateValues(facet) {
      if (!facet) return
      var self = this

      // annoyingly the mouseXX event handlers break the transitions, so block them for a time.
      if (!self.$_animating) {
        self.$_animating = true
        setTimeout(() => {
          self.$_animating = false
        }, 1500)
      }

      var max = _.maxBy(facet.terms, 'count') // max range
      var colorScale = d3.scaleSequential([0, max ? max.count : 1], d3.interpolateYlOrBr)

      this.$_mychart
        .selectAll('path')
        .transition()
        .duration(1000)
        .attr('fill', function (d) {
          var c = d.properties.code
          var t = _.find(facet.terms, { term: c })
          d.count = t ? t.count : 0
          return colorScale(d.count)
        })
    },
    genData() {
      return {
        values: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [122.098542953878, -33.90826116877982],
                    [119.95971679687499, -34.02534773814796],
                    [118.46557617187499, -34.80478291957242],
                    [116.773681640625, -35.128894341010515],
                    [115.12573242187499, -34.3343644870263],
                    [115.01586914062499, -33.660353121928814],
                    [115.71899414062499, -33.53223722395907],
                    [115.74096679687497, -32.57459172113418],
                    [114.90600585937499, -29.372601506681427],
                    [113.016357421875, -25.77021384896025],
                    [114.23583984374999, -26.2145910237943],
                    [113.49975585937499, -24.457150524185852],
                    [113.91723632812499, -21.97361354260756],
                    [114.35668945312499, -22.603868842895686],
                    [114.75219726562499, -21.79010705980786],
                    [116.619873046875, -20.745840238902257],
                    [118.14346482887798, -20.469730776405765],
                    [121.37344529762798, -19.36452849904292],
                    [122.142488266378, -18.314372950451837],
                    [122.17895507812499, -17.06728740376787],
                    [123.07983398437499, -16.56249250837488],
                    [123.51928710937499, -17.382094947877505],
                    [123.651123046875, -16.309595517742185],
                    [124.552001953125, -16.309595517742185],
                    [124.53002929687499, -15.548960445891245],
                    [125.40893554687497, -14.764259178591587],
                    [126.94702148437499, -13.827412236077283],
                    [128.199462890625, -14.902321826141796],
                    [128.98910522460938, -14.88374160690319],
                    [128.99459838867188, -31.687276157912944],
                    [127.525789047628, -32.23278153512826],
                    [126.14151170387798, -32.2513660435202],
                    [124.64737107887798, -32.91786662694785],
                    [124.1365068210655, -33.06990659393831],
                    [123.57071092262798, -33.935610490349646],
                    [122.098542953878, -33.90826116877982],
                  ],
                ],
              },
              properties: {
                code: 'Western Australia',
              },
            },
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [129.7944658054405, -15.105534930866195],
                    [129.448396469503, -14.170129653265384],
                    [129.975740219503, -13.444652488866994],
                    [130.151521469503, -12.7598363981436],
                    [131.118318344503, -12.244999520227921],
                    [132.744294907003, -12.416725546478498],
                    [132.436677719503, -11.384722289096707],
                    [134.666902328878, -12.094647505072581],
                    [136.842195297628, -12.169834148411804],
                    [136.501619125753, -13.145280103587151],
                    [135.776521469503, -13.401907691538606],
                    [136.040193344503, -13.786334595630988],
                    [135.996248032003, -14.084897296160365],
                    [135.468904282003, -14.553276711021436],
                    [135.732576157003, -15.190371722634517],
                    [136.919099594503, -15.952348110678706],
                    [137.99301316872175, -16.548273409534293],
                    [137.9996109008789, -25.999864268396294],
                    [128.9956283569336, -26.00156142855682],
                    [129.00009155273438, -14.887723217337792],
                    [129.7944658054405, -15.105534930866195],
                  ],
                ],
              },
              properties: {
                code: 'Northern Territory',
              },
            },
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [139.00100877419052, -35.59835697833787],
                    [138.08090379372177, -35.62961698345198],
                    [137.3420732273155, -36.01711311787323],
                    [136.556550766378, -35.91930435569171],
                    [136.88064744606552, -35.64301038830108],
                    [137.81173875465925, -35.72778314565059],
                    [138.45718553200302, -35.347837606055315],
                    [138.23745896950305, -34.3742700372883],
                    [137.71011521950302, -35.168417902087285],
                    [136.78726365700302, -35.132486322759384],
                    [137.402498032003, -34.51922836584454],
                    [137.885896469503, -33.05839758814378],
                    [136.78726365700302, -33.71885488465307],
                    [135.55679490700302, -34.88953360724973],
                    [135.15030076637805, -33.9606729876306],
                    [134.30435350075305, -33.14122882511328],
                    [133.49685838356552, -32.267624368867814],
                    [132.4072265625, -31.987112306515524],
                    [131.2646484375, -31.48255113312247],
                    [128.98086547851562, -31.688444722033857],
                    [128.9959716796875, -26.00495567531537],
                    [137.9996109008789, -26.003721415115685],
                    [140.9820556640625, -26.002487141945736],
                    [140.9813690185547, -34.04583232505719],
                    [140.94085693359378, -33.988918483762156],
                    [140.95870971679688, -38.05890484918669],
                    [139.93286132812503, -37.446516047833505],
                    [139.60327148437506, -36.09127994838078],
                    [139.00100877419052, -35.59835697833787],
                  ],
                ],
              },
              properties: {
                code: 'South Australia',
              },
            },
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [142.66845703125, -34.53823752729576],
                    [143.22326660156253, -34.70097741472009],
                    [143.38256835937503, -35.16482750605028],
                    [144.76135253906256, -36.12456474813333],
                    [145.03601074218753, -35.799993929885275],
                    [146.95312500000003, -36.0624217151089],
                    [147.96386718750003, -35.97356075349625],
                    [148.13964843750003, -36.778492404594175],
                    [149.9688720703125, -37.507547084964116],
                    [149.55688476562497, -37.75334401310657],
                    [147.54287889137805, -37.96065514530885],
                    [147.01904296875, -38.54386917587614],
                    [146.27395799294055, -38.92009871527055],
                    [145.2447509765625, -38.53957267203904],
                    [144.59304978981558, -38.112083413704994],
                    [143.48693847656253, -38.869651824083554],
                    [142.61352539062503, -38.44928681715353],
                    [141.50390625000003, -38.36750215395045],
                    [140.96832275390625, -38.057823542908295],
                    [140.96488952636716, -33.9946115848146],
                    [141.00608825683594, -34.04753915067274],
                    [142.09716796875003, -34.161818161230386],
                    [142.5421142578125, -34.759666124662495],
                    [142.66845703125, -34.53823752729576],
                  ],
                ],
              },
              properties: {
                code: 'Victoria',
              },
            },
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [150.897216796875, -28.65685103420342],
                    [149.556884765625, -28.618281599983867],
                    [148.99658203125, -29.00333620017934],
                    [140.98892211914062, -29.00453726181703],
                    [141.009521484375, -26.002487141945736],
                    [138.0025291442871, -25.999864268396294],
                    [138.0157470703125, -16.557227187102736],
                    [140.08666992187503, -17.732990849540247],
                    [141.0205078125, -17.161785912715136],
                    [141.61376953125, -14.881087159090647],
                    [141.558837890625, -12.774303696888841],
                    [142.2509765625, -10.811724143275514],
                    [142.91015625, -11.415418041941416],
                    [143.84399414062503, -14.503826315249013],
                    [144.70092773437503, -14.354869561024987],
                    [145.272216796875, -15.247089731205994],
                    [146.46896531715927, -18.972790016127746],
                    [148.366853500753, -20.07811555521466],
                    [148.850251938253, -20.531474057960363],
                    [149.817048813253, -22.615529802194562],
                    [150.0202958835655, -22.239782974161628],
                    [150.47622850075302, -22.41254968140657],
                    [151.091462875753, -23.666157802397777],
                    [152.343904282003, -24.748325626432067],
                    [152.948152328878, -25.48443653060751],
                    [153.2283036960655, -24.997507060278355],
                    [153.3313005222374, -25.131855598760204],
                    [153.03329637184677, -25.77169563610003],
                    [153.22281053200302, -27.212134409077322],
                    [153.53118896484375, -28.168875180063345],
                    [153.10821533203125, -28.357567857801705],
                    [152.86651611328125, -28.323724553546015],
                    [152.78137207031253, -28.3648185914011],
                    [152.46826171875, -28.268101445445343],
                    [151.97662353515625, -28.56281321321839],
                    [152.07275390625, -28.721904784758895],
                    [151.99859619140628, -28.91201470125561],
                    [151.7266845703125, -28.88796934830344],
                    [151.3531494140625, -29.190532832294593],
                    [150.897216796875, -28.65685103420342],
                  ],
                ],
              },
              properties: {
                code: 'Queensland',
              },
            },
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [141.009521484375, -34.03445260967644],
                    [140.9892654418945, -29.00904111863828],
                    [149.0020751953125, -29.01294430242485],
                    [149.55963134765625, -28.630336071108875],
                    [150.89447021484375, -28.671310915880834],
                    [151.358642578125, -29.197725900405835],
                    [151.7376708984375, -28.902397228558485],
                    [152.00408935546875, -28.926439238621022],
                    [152.08648681640625, -28.721904784758895],
                    [151.99310302734375, -28.562813213218377],
                    [152.47100830078125, -28.27535828181708],
                    [152.78411865234372, -28.374485465612292],
                    [152.8692626953125, -28.32614228994993],
                    [153.09722900390622, -28.37690204653979],
                    [153.54217529296875, -28.183401855246004],
                    [153.5888671875, -28.87353946316266],
                    [153.0670166015625, -30.240086360983412],
                    [153.028564453125, -30.977609093348676],
                    [152.259521484375, -32.57459172113417],
                    [151.358642578125, -33.56886118255556],
                    [150.45501708984375, -35.353216101238225],
                    [150.128173828125, -36.08018188118014],
                    [149.92218017578125, -36.888408043138206],
                    [149.97161865234375, -37.50318937824071],
                    [148.1451416015625, -36.78069226486258],
                    [147.974853515625, -35.964669147704065],
                    [146.96136474609375, -36.055760619006755],
                    [145.02777099609375, -35.795538497992624],
                    [144.7613525390625, -36.11569018065339],
                    [143.39080810546875, -35.1670728020265],
                    [143.228759765625, -34.696461172723474],
                    [142.66571044921872, -34.52918706954934],
                    [142.53936767578125, -34.7506398050501],
                    [142.08892822265625, -34.150454031914464],
                    [141.009521484375, -34.03445260967644],
                  ],
                  [
                    [148.3154296875, -35.06597313798423],
                    [148.21105957031256, -35.30840140169167],
                    [148.33740234375003, -35.97356075349629],
                    [148.51318359375003, -36.13787471840732],
                    [148.61206054687503, -36.01800375871421],
                    [148.69445800781256, -36.093499373805805],
                    [148.61755371093753, -36.230981283477966],
                    [148.798828125, -36.40359962073259],
                    [149.08447265625006, -36.518465989675946],
                    [149.3426513671875, -36.1910920218246],
                    [149.38110351562506, -35.277016331398876],
                    [149.52392578125, -35.029996369025696],
                    [149.83703613281253, -35.07946034047984],
                    [149.95788574218756, -34.93097858831634],
                    [149.4635009765625, -34.75063980505014],
                    [149.17785644531256, -34.3570416007608],
                    [148.23852539062503, -34.97150033361737],
                    [148.3154296875, -35.06597313798423],
                  ],
                ],
              },
              properties: {
                code: 'New South Wales',
              },
            },
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [148.3154296875, -35.06597313798423],
                    [148.23852539062503, -34.97150033361737],
                    [149.17785644531256, -34.3570416007608],
                    [149.4635009765625, -34.75063980505014],
                    [149.95788574218756, -34.93097858831634],
                    [149.83703613281253, -35.07946034047984],
                    [149.52392578125, -35.029996369025696],
                    [149.38110351562506, -35.277016331398876],
                    [149.3426513671875, -36.1910920218246],
                    [149.08447265625006, -36.518465989675946],
                    [148.798828125, -36.40359962073259],
                    [148.61755371093753, -36.230981283477966],
                    [148.69445800781256, -36.093499373805805],
                    [148.61206054687503, -36.01800375871421],
                    [148.51318359375003, -36.13787471840732],
                    [148.33740234375003, -35.97356075349629],
                    [148.21105957031256, -35.30840140169167],
                    [148.3154296875, -35.06597313798423],
                  ],
                ],
              },
              properties: {
                code: 'ACT',
              },
            },
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [144.6375377150148, -40.70182990187264],
                    [145.3122432945131, -40.74609268582531],
                    [146.2900264976381, -41.16095695591391],
                    [147.4216182945131, -41.03677144936819],
                    [148.1137569663881, -40.62946376760081],
                    [148.3224972007631, -40.91235121473863],
                    [148.3334835288881, -41.67176389960902],
                    [148.0698116538881, -42.50336988342703],
                    [147.7951534507631, -42.8667861228948],
                    [147.2787960288881, -43.107882072481196],
                    [146.9162472007631, -43.571318326791655],
                    [146.0987193556398, -43.544916803249706],
                    [145.5933482618898, -43.09732471653573],
                    [145.1538951368898, -42.20853434613753],
                    [145.4834849806398, -42.411647252840304],
                    [145.3406627150148, -42.184116759747084],
                    [144.5935924025148, -41.034151599806265],
                    [144.6375377150148, -40.70182990187264],
                  ],
                ],
              },
              properties: {
                code: 'Tasmania',
              },
            },
          ],
        },

        format: { property: 'features' },
      }
    },
  },
}
</script>
