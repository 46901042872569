<template>
  <div :id="'vword' + this.facetCode">
    <div v-if="loading" class="loading">
      <span class="loading-spinner spinner-lg"></span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.loading {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #fffa;
  position: absolute;

  width: 100%;
  height: 100%;

  .spinner-grow {
    width: 2em;
    height: 2em;
  }
}
</style>

<script>
import _ from 'lodash'
import colours from '@/mixins/colours'
import navigation from '@/mixins/navigation'
import numbro from 'numbro'
import murmurhash from 'murmurhash'

import * as d3 from 'd3'
import * as d3Cloud from 'd3-cloud'

export default {
  name: 'WordVis',
  mixins: [colours, navigation],
  props: {
    facet: {
      type: Object,
      required: false,
    },
    facetCode: {
      type: String,
      required: true,
    },
    singular: {
      type: String,
      required: false,
      default: 'item',
    },
    plural: {
      type: String,
      required: false,
      default: 'items',
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    angles: {
      type: Array,
      required: false,
      default: () => [-15],
    },
    colours: {
      type: Array,
      required: false,
      default: () => ['violet', 'magenta', 'blue', 'light-blue'],
    },
  },
  data() {
    return {
      v: [],
    }
  },
  mounted() {
    const height = 400
    const width = 1200

    var vis = d3.select('#vword' + this.facetCode)

    var tooltip = vis
      .append('div')
      .attr('class', 'tooltip')
      .style('background-color', 'white')
      .style('opacity', 0)
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('padding', '5px')

    var svg = vis
      .append('svg')
      .attr('viewBox', [0, 0, width, height])
      .append('g')
      .attr('transform', 'translate(' + ~~(width / 2) + ',' + ~~(height / 2) + ')')

    this.$_mychart = vis
    this.$_mysvg = svg
    this.$_mytooltip = tooltip

    this.updateValues(null)
  },
  watch: {
    facet(newValue) {
      this.updateValues(newValue)
    },
  },
  computed: {
    visColours() {
      return _.map(this.colours, (n) => this.colour(n))
    },
  },
  methods: {
    termChanged(termId) {
      this.routeTo(this.generateFacetLink(this.facetCode, termId))
    },
    updateValues(facet) {
      var self = this

      const height = 400
      const width = 1200

      const UNIFORM_THRESHOLD = 10 // mix it up if more than this many words in cloud.

      if (!facet) return

      let recurseTerms = function (terms) {
        return !terms
          ? []
          : _.reduce(
              terms,
              (allTerms, term) => allTerms.concat([term], recurseTerms(term.subTerms)),
              []
            )
      }
      var nf = recurseTerms(facet.terms)

      var max = _.maxBy(nf, 'count') // max range
      var sizescale = d3
        .scalePow()
        .exponent(0.5)
        .domain([1, max ? max.count : 2])
        .range([14, 30]) // font size range

      var newWords = nf.map((x) => {
        return {
          count: x.count,
          size: sizescale(x.count),
          text: x.displayTerm.replace(/\s*;\s*/g, '/'),
          term: x.term,
        }
      })

      // annoyingly the mouseXX event handlers break the transitions, so block them for a time.
      if (!self.$_animating) {
        self.$_animating = true
        setTimeout(() => {
          self.$_animating = false
        }, 2000)
      }

      let mouseOver = function () {
        if (self.$_animating) return

        self.$_mytooltip.transition().duration(100).style('opacity', 0.9)

        d3.select(this)
          .transition()
          .duration(100)
          .style('font-size', (d) => 1.1 * d.size + 'px')
      }

      let mouseLeave = function () {
        if (self.$_animating) return

        self.$_mytooltip.transition().duration(100).style('opacity', 0)

        d3.select(this)
          .transition()
          .duration(100)
          .style('font-size', (d) => (1 / 1.1) * d.size + 'px')
      }

      let mouseMove = function (d) {
        if (self.$_animating) return

        var desc = d.count === 1 ? self.singular : self.plural
        self.$_mytooltip
          .html(numbro(d.count).format({ thousandSeparated: true }) + ' ' + desc)
          .style('left', '20px')
          .style('top', '20px')
      }

      let click = function (d) {
        self.termChanged(d.term)
      }

      d3Cloud()
        .size([width, height])
        .words(newWords)
        .text((d) => d.text)
        .padding(2)
        .fontWeight('bold')
        .fontSize((d) => d.size)
        .rotate(() => {
          var j = newWords.length < UNIFORM_THRESHOLD ? 0 : Math.random() * self.angles.length
          return self.angles[~~j]
        })
        .on('end', draw)
        .start()

      function draw(words) {
        var svg = self.$_mysvg
        const t = svg.transition().duration(1500)

        svg
          .selectAll('text')
          .data(words, (d) => d.text)
          .join(
            (enter) =>
              enter
                .append('text')
                .style('font-size', (d) => d.size + 'px')
                .style('fill', function (d) {
                  var h = murmurhash.v3(d.text) % self.visColours.length
                  return self.visColours[h]
                })
                .attr('transform', 'scale(0)')
                .attr('opacity', '0.4')
                .style('user-select', 'none')
                .attr('text-anchor', 'middle')
                .attr('font-weight', 'bold')
                .attr('class', 'cloud')
                .text((d) => d.text)
                .on('mouseover', mouseOver)
                .on('mousemove', mouseMove)
                .on('mouseleave', mouseLeave)
                .on('click', click)
                .call((enter) =>
                  enter
                    .transition(t)
                    .attr('opacity', '1')
                    .attr('transform', function (d) {
                      return 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')'
                    })
                ),
            (update) =>
              update
                .style('user-select', 'none')
                .attr('text-anchor', 'middle')
                .attr('font-weight', 'bold')
                .attr('class', 'cloud')
                .call((update) =>
                  update
                    .transition(t)
                    .style('font-size', (d) => d.size + 'px')
                    .attr('opacity', 1)
                    .attr('transform', function (d) {
                      return 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')'
                    })
                    .style('fill', function (d) {
                      var h = murmurhash.v3(d.text) % self.visColours.length // hash: maintain colour
                      return self.visColours[h]
                    })
                ),
            (exit) => exit.remove()
          )
      }
    },
  },
}
</script>
