<template>
  <div :id="'vmap' + this.facetCode">
    <div v-if="loading" class="loading">
      <span class="loading-spinner spinner-lg"></span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.loading {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #fffa;
  position: absolute;

  width: 100%;
  height: 100%;

  .spinner-grow {
    width: 2em;
    height: 2em;
  }
}
</style>

<script>
import _ from 'lodash'
import colours from '@/mixins/colours'
import navigation from '@/mixins/navigation'
import numbro from 'numbro'

import * as d3 from 'd3'

export default {
  name: 'AustlangMapVis',
  mixins: [colours, navigation],
  props: {
    facet: {
      type: Object,
      required: false,
    },
    facetCode: {
      type: String,
      required: true,
    },
    singular: {
      type: String,
      required: false,
      default: 'item',
    },
    plural: {
      type: String,
      required: false,
      default: 'items',
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  watch: {
    facet(newValue) {
      this.updateValues(newValue)
    },
  },
  mounted() {
    const height = 400
    const width = 1200

    var vis = d3.select('#vmap' + this.facetCode)

    var tooltip = vis
      .append('div')
      .attr('class', 'tooltip')
      .style('background-color', 'white')
      .style('opacity', 0)
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('padding', '5px')

    const svg = vis.append('svg').attr('viewBox', [0, 0, width, height])

    this.$_mychart = svg

    var projection = d3
      .geoMercator()
      .scale(575)
      .center([165, -28.5])
      .translate([width / 2, height / 2])

    var self = this
    var ausMap = this.genData().values.features

    let mouseOver = function () {
      if (self.$_animating) return

      tooltip.transition().duration(100).style('opacity', 0.9)

      d3.selectAll('.Region')
        .transition()
        .duration(100)
        .style('opacity', 0.8)
        .style('stroke', '#aaa')

      d3.select(this).transition().duration(100).style('opacity', 1).style('stroke', 'black')
    }

    let mouseLeave = function () {
      if (self.$_animating) return

      tooltip.transition().duration(100).style('opacity', 0)

      d3.selectAll('.Region').transition().duration(100).style('stroke', '#aaa').style('opacity', 1)

      d3.select(this).transition().duration(100).style('stroke', '#aaa')
    }

    let mouseMove = function (d) {
      if (self.$_animating) return

      var desc = d.count === 1 ? self.singular : self.plural
      tooltip
        .html(numbro(d.count).format({ thousandSeparated: true }) + ' ' + desc)
        .style('left', '20px')
        .style('top', '20px')
    }

    let click = function (d) {
      tooltip.style('opacity', 0)
      self.termChanged(d.properties.code)
    }

    self.$_mychart
      .append('g')
      .selectAll('path')
      .data(ausMap)
      .enter()
      .append('path') // draw each region
      .attr('d', d3.geoPath().projection(projection))
      .attr('id', function (d) {
        return d.properties.code
      })
      .attr('stroke-width', function () {
        return 1
      })
      .attr('stroke', '#aaa')
      .attr('class', function () {
        return 'Region'
      })
      .on('click', click)
      .on('mouseover', mouseOver)
      .on('mousemove', mouseMove)
      .on('mouseleave', mouseLeave)

    // outline - expand, colour, blur
    var filter = self.$_mychart.append('defs').append('filter').attr('id', 'water')

    filter
      .append('feMorphology')
      .attr('result', 'morphOut')
      .attr('in', 'SourceAlpha')
      .attr('operator', 'dilate')
      .attr('radius', '5')

    filter
      .append('feColorMatrix')
      .attr('result', 'matrixOut')
      .attr('in', 'morphOut')
      .attr('type', 'matrix')
      .attr(
        'values',
        '0 0 0 0 0.5 ' + // r
          '0 0 0 0 0.7 ' + // g
          '0 0 0 0 0.9 ' + // b
          '0 0 0 1 0'
      )

    filter
      .append('feGaussianBlur')
      .attr('stdDeviation', '5')
      .attr('in', 'matrixOut')
      .attr('result', 'blurOut')

    filter
      .append('feBlend')
      .attr('in', 'SourceGraphic')
      .attr('in2', 'blurOut')
      .attr('mode', 'normal')

    d3.select('g').style('filter', 'url(#water)')
  },
  methods: {
    termChanged(termId) {
      this.routeTo(this.generateFacetLink(this.facetCode, termId))
    },
    updateValues(facet) {
      if (!facet) return
      var self = this

      // annoyingly the mouseXX event handlers break the transitions, so block them for a time.
      if (!self.$_animating) {
        self.$_animating = true
        setTimeout(() => {
          self.$_animating = false
        }, 1500)
      }

      var max = _.maxBy(facet.terms, 'count') // max range
      var colorScale = d3.scaleSequential([0, max ? max.count : 1], d3.interpolateYlOrBr)
      this.$_mychart
        .selectAll('path')
        .transition()
        .duration(1000)
        .attr('fill', function (d) {
          var c = d.properties.code
          var t = _.find(facet.terms, { displayTerm: c })
          d.count = t ? t.count : 0
          return colorScale(d.count)
        })
    },
    genData() {
      return {
        values: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [122.109529282003, -33.8991427774176],
                    [121.8623368991905, -32.64076046171472],
                    [118.725740219503, -32.018786740255834],
                    [117.132722641378, -30.511634190652682],
                    [117.9731767429405, -22.65608999962632],
                    [118.802644516378, -21.77123098578646],
                    [118.989412094503, -20.993750477584598],
                    [118.154451157003, -20.459437812157756],
                    [121.384431625753, -19.354163358751673],
                    [122.153474594503, -18.303942802146015],
                    [123.559724594503, -18.928628902718682],
                    [127.382966782003, -18.762270773062436],
                    [129.008943344503, -18.301335166913685],
                    [129.008943344503, -31.682833348374672],
                    [127.536775375753, -32.223487855226765],
                    [126.152498032003, -32.24207426460847],
                    [124.658357407003, -32.90864366843632],
                    [124.1474931491905, -33.06069950969995],
                    [123.581697250753, -33.9264950253723],
                    [122.109529282003, -33.8991427774176],
                  ],
                ],
              },
              properties: {
                code: 'A',
              },
            },

            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [129.7944658054405, -15.105534930866195],
                    [129.448396469503, -14.170129653265384],
                    [129.975740219503, -13.444652488866994],
                    [130.151521469503, -12.7598363981436],
                    [131.118318344503, -12.244999520227921],
                    [132.744294907003, -12.416725546478498],
                    [132.436677719503, -11.384722289096707],
                    [134.666902328878, -12.094647505072581],
                    [136.842195297628, -12.169834148411804],
                    [136.501619125753, -13.145280103587151],
                    [135.776521469503, -13.401907691538606],
                    [136.040193344503, -13.786334595630988],
                    [135.996248032003, -14.084897296160365],
                    [135.468904282003, -14.553276711021436],
                    [135.732576157003, -15.190371722634517],
                    [136.919099594503, -15.952348110678706],
                    [137.99301316872175, -16.548273409534293],
                    [138.017732407003, -17.760716782541188],
                    [136.688386703878, -17.6822292969572],
                    [135.337068344503, -16.669344963946187],
                    [131.557771469503, -16.753523530765776],
                    [131.2446611179405, -15.524083548809278],
                    [129.7944658054405, -15.105534930866195],
                  ],
                ],
              },
              properties: {
                code: 'N',
              },
            },

            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [139.0229814304405, -35.6005902409664],
                    [138.10287644997175, -35.63184937345576],
                    [137.3640458835655, -36.019334635813344],
                    [136.578523422628, -35.92152862747983],
                    [136.9026201023155, -35.64524240422388],
                    [137.83371141090925, -35.73001279102207],
                    [138.479158188253, -35.350077837913496],
                    [138.259431625753, -34.37653694512878],
                    [137.732087875753, -35.1706630989329],
                    [136.809236313253, -35.13473251127636],
                    [137.424470688253, -34.521491343076654],
                    [137.907869125753, -33.06069950969995],
                    [136.809236313253, -33.72113938288209],
                    [135.578767563253, -34.89178647779075],
                    [135.172273422628, -33.962951030490785],
                    [134.326326157003, -33.143528578249956],
                    [133.5188310398155, -32.269946749049794],
                    [133.4309404148155, -31.237695545321326],
                    [133.963777328878, -30.175047036908506],
                    [135.1118486179405, -27.699577028845585],
                    [137.0014970554405, -26.191424073870643],
                    [138.028718735128, -25.99902885654597],
                    [139.094392563253, -25.91506540856041],
                    [140.193025375753, -24.922805409584644],
                    [141.115876938253, -23.721489150681524],
                    [141.632234360128, -22.50646503247806],
                    [142.653962875753, -20.546905988816203],
                    [143.7471025241905, -20.194157190950925],
                    [143.70590379372175, -21.473770795297302],
                    [143.796541000753, -22.71184070414576],
                    [145.2302568210655, -24.518636527901982],
                    [144.8712158203125, -25.460634718477525],
                    [143.83026123046875, -25.715786129968627],
                    [142.082673813253, -27.485367799007268],
                    [141.555330063253, -28.91826338775671],
                    [141.006013657003, -29.4960291818998],
                    [140.984041000753, -34.878268327971604],
                    [139.83871629372175, -34.75424839314883],
                    [139.0229814304405, -35.6005902409664],
                  ],
                ],
              },
              properties: {
                code: 'L',
              },
            },

            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [140.984041000753, -34.86474795461368],
                    [141.884919907003, -35.05832734997331],
                    [143.159333969503, -35.845868304706784],
                    [145.664216782003, -36.484463594636416],
                    [148.872224594503, -36.20128585693467],
                    [149.531404282003, -35.524624361147616],
                    [149.048005844503, -32.018786740255834],
                    [151.761628891378, -32.96857571705904],
                    [151.025544907003, -34.15408864668937],
                    [149.948884750753, -36.638890417690625],
                    [149.7016923679405, -37.732926217303614],
                    [147.5813310398155, -37.95848961653533],
                    [146.312410141378, -38.917961779600994],
                    [144.631501938253, -38.109922357606045],
                    [143.466951157003, -38.95214502721728],
                    [141.621248032003, -38.282604705997876],
                    [140.171052719503, -37.711201083712346],
                    [139.643708969503, -36.97754129420899],
                    [139.555818344503, -36.13033065580146],
                    [139.02572801247175, -35.6005902409664],
                    [139.511873032003, -35.094291638800804],
                    [139.8359697116905, -34.74747830177997],
                    [140.984041000753, -34.86474795461368],
                  ],
                ],
              },
              properties: {
                code: 'S',
              },
            },

            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [149.048005844503, -32.02111546696525],
                    [150.465242172628, -31.519081477217334],
                    [150.948640610128, -30.274721473675328],
                    [151.036531235128, -27.426874557713756],
                    [149.278718735128, -26.211139006461167],
                    [148.092195297628, -26.250558852643927],
                    [146.949617172628, -25.73706313144612],
                    [147.125398422628, -24.7433368796742],
                    [145.2302568210655, -24.503642157232807],
                    [144.488679672628, -23.580599651539156],
                    [143.796541000753, -22.714374288141414],
                    [143.708650375753, -21.472492825328235],
                    [143.741609360128, -20.1967349143946],
                    [142.9231279148155, -19.0117459898618],
                    [146.45797898903425, -18.96759519778116],
                    [148.355867172628, -20.07295615084645],
                    [148.839265610128, -20.52632973501056],
                    [149.806062485128, -22.610458935903033],
                    [150.0093095554405, -22.234698365259497],
                    [150.465242172628, -22.40747136417143],
                    [151.080476547628, -23.66112651746649],
                    [152.332917953878, -24.7433368796742],
                    [152.937166000753, -25.479477737093763],
                    [153.2173173679405, -24.99252836106161],
                    [153.32031419411237, -25.1268823558579],
                    [153.02231004372175, -25.766748753822572],
                    [153.211824203878, -27.207249123820304],
                    [153.585359360128, -28.682393276310666],
                    [153.0964677585655, -30.487968790944173],
                    [152.893220688253, -31.603332687516396],
                    [151.761628891378, -32.996222959686946],
                    [150.509187485128, -32.52504519337263],
                    [149.048005844503, -32.02111546696525],
                  ],
                ],
              },
              properties: {
                code: 'E',
              },
            },

            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [140.9455888523155, -17.163358087456572],
                    [141.709138657003, -15.063103804031208],
                    [141.665193344503, -12.716972714260494],
                    [142.324373032003, -10.996735235138884],
                    [142.939607407003, -11.427799661673921],
                    [143.115388657003, -12.459639415677211],
                    [143.598787094503, -12.845541961530945],
                    [143.862458969503, -14.553276711021436],
                    [144.785310532003, -14.425634016350347],
                    [145.224763657003, -15.147957577586375],
                    [145.664216782003, -16.921768858522224],
                    [146.070710922628, -17.388907435734954],
                    [146.235505844503, -18.55409229354834],
                    [146.466218735128, -18.970192627192343],
                    [144.829255844503, -18.970192627192343],
                    [142.92587449684675, -19.016939430671222],
                    [142.203523422628, -18.345659623568014],
                    [140.9455888523155, -17.163358087456572],
                  ],
                ],
              },
              properties: {
                code: 'Y',
              },
            },

            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [129.01168992653425, -18.298727492428625],
                    [127.38571336403425, -18.762270773062436],
                    [123.559724594503, -18.928628902718682],
                    [122.16171434059675, -18.311765472278516],
                    [122.153474594503, -17.21583604139144],
                    [122.933503891378, -16.490342703779255],
                    [123.515779282003, -17.760716782541188],
                    [123.658601547628, -16.2268035229989],
                    [124.493562485128, -16.564069675621717],
                    [124.515535141378, -15.656360065513438],
                    [125.185701157003, -15.190371722634517],
                    [126.141511703878, -14.127517463748932],
                    [127.185212875753, -13.861011402972848],
                    [128.108064438253, -14.956989028117452],
                    [128.701326157003, -14.553276711021436],
                    [129.799958969503, -15.105534930866195],
                    [131.250154282003, -15.529376243555276],
                    [131.557771469503, -16.758783457295774],
                    [130.327302719503, -17.383665244034624],
                    [129.887849594503, -17.383665244034624],
                    [129.01168992653425, -18.298727492428625],
                  ],
                ],
              },
              properties: {
                code: 'K',
              },
            },

            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [138.0012529148155, -16.556171704409202],
                    [138.918611313253, -16.86920826355294],
                    [139.5722978366905, -17.467522265142378],
                    [140.3083818210655, -17.70839560884065],
                    [140.940095688253, -17.163358087456572],
                    [142.214509750753, -18.335231361470473],
                    [142.961580063253, -19.0429042003383],
                    [143.74984910622175, -20.207045381515485],
                    [142.65670945778425, -20.546905988816203],
                    [141.63498094215925, -22.510271068557547],
                    [141.115876938253, -23.724003654627975],
                    [140.19027879372175, -24.922805409584644],
                    [139.09713914528425, -25.91506540856041],
                    [138.0232255710655, -25.99902885654597],
                    [138.0012529148155, -16.556171704409202],
                  ],
                ],
              },
              properties: {
                code: 'G',
              },
            },

            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [118.14348417121755, -20.448374245040576],
                    [118.97295194465505, -20.987854688493048],
                    [118.79717069465505, -21.76536643491059],
                    [117.96220975715505, -22.660400923272146],
                    [117.12724881965505, -30.51092627909526],
                    [118.72026639778005, -32.008774476677544],
                    [121.87334256965505, -32.64469407666812],
                    [122.10954862434255, -33.90757921779057],
                    [119.67058378059255, -34.030582974433415],
                    [119.23662381965505, -34.47554274673082],
                    [117.91826444465505, -35.125075328070764],
                    [116.42412381965505, -34.981177381723555],
                    [115.14970975715505, -34.11248657427419],
                    [114.88603788215505, -33.71131750806697],
                    [115.63310819465505, -33.52834483077264],
                    [115.89678006965505, -32.27389658901475],
                    [115.10576444465505, -30.2455435366577],
                    [114.53447538215505, -28.792454189155894],
                    [113.12822538215505, -26.022974197145828],
                    [113.96318631965505, -26.259674836284837],
                    [113.56767850715505, -24.8323579463894],
                    [113.74345975715505, -22.822516621377442],
                    [114.13896756965505, -21.969283268308867],
                    [114.22685819465505, -22.538688266915734],
                    [116.07256131965505, -20.987854688493048],
                    [118.14348417121755, -20.448374245040576],
                  ],
                ],
              },
              properties: {
                code: 'W',
              },
            },

            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [129.00896268684255, -31.677459449299384],
                    [128.99797635871755, -18.292731859556028],
                    [129.87688260871755, -17.367153537553015],
                    [130.31633573371755, -17.388123294976737],
                    [131.56328397590505, -16.757996663378087],
                    [135.32610135871755, -16.658032828658943],
                    [136.68291288215505, -17.670978786157146],
                    [138.01225858528005, -17.749471194012013],
                    [138.03423124153005, -26.003227507614067],
                    [137.00700956184255, -26.195615824628124],
                    [135.10088163215505, -27.70857639676599],
                    [133.95830350715505, -30.169587731953733],
                    [133.43095975715505, -31.22759887409804],
                    [133.50786405403005, -32.22279272207806],
                    [131.27763944465505, -31.490280158456024],
                    [129.00896268684255, -31.677459449299384],
                  ],
                ],
              },
              properties: {
                code: 'C',
              },
            },

            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [141.0032864173113, -29.500095102128668],
                    [141.54985624153005, -28.9175441497953],
                    [142.08818631965505, -27.479765566437077],
                    [143.8275146484375, -25.715786129968627],
                    [144.8712158203125, -25.458154856592074],
                    [145.2330227454363, -24.515389976380572],
                    [147.12541776496755, -24.74508503694843],
                    [146.9523830969988, -25.741271101863855],
                    [148.09221463996755, -26.25474847301595],
                    [149.2759914954363, -26.21286595220126],
                    [151.03655057746755, -27.428583075905102],
                    [150.95415311653005, -30.288242901229832],
                    [150.46526151496755, -31.527746083275208],
                    [149.0452786048113, -32.02274747583552],
                    [149.5286770423113, -35.528426222871545],
                    [148.8749905188738, -36.20727160508862],
                    [145.6669827063738, -36.49042761509852],
                    [143.1566067298113, -35.854107282760026],
                    [141.87944608528005, -35.06215117675418],
                    [140.97856717903005, -34.86858082570172],
                    [141.0032864173113, -29.500095102128668],
                  ],
                ],
              },
              properties: {
                code: 'D',
              },
            },

            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [144.6375377150148, -40.70182990187264],
                    [145.3122432945131, -40.74609268582531],
                    [146.2900264976381, -41.16095695591391],
                    [147.4216182945131, -41.03677144936819],
                    [148.1137569663881, -40.62946376760081],
                    [148.3224972007631, -40.91235121473863],
                    [148.3334835288881, -41.67176389960902],
                    [148.0698116538881, -42.50336988342703],
                    [147.7951534507631, -42.8667861228948],
                    [147.2787960288881, -43.107882072481196],
                    [146.9162472007631, -43.571318326791655],
                    [146.0987193556398, -43.544916803249706],
                    [145.5933482618898, -43.09732471653573],
                    [145.1538951368898, -42.20853434613753],
                    [145.4834849806398, -42.411647252840304],
                    [145.3406627150148, -42.184116759747084],
                    [144.5935924025148, -41.034151599806265],
                    [144.6375377150148, -40.70182990187264],
                  ],
                ],
              },
              properties: {
                code: 'T',
              },
            },
          ],
        },

        format: { property: 'features' },
      }
    },
  },
}
</script>
