<template>
  <div>
    <h1>Error</h1>
    <p>The requested resource could not be found.</p>
  </div>
</template>

<script>
import service from '@/service/service'

export default {
  name: 'VersionView',
  mounted() {
    let versionId = this.$router.history.current.params.versionId
    if (versionId) {
      service.work.workIdFromVersionId(versionId).then((response) => {
        if (response == null) {
          // TODO show alert
          this.$router.push(this.$router.go(-1))
        } else {
          this.$router.replace({
            name: 'Work',
            params: { id: response, versionId: versionId },
          })
        }
      })

      return
    }

    let nbdId = this.$router.history.current.params.nbdId
    if (nbdId) {
      service.work.workIdFromNbdId(nbdId).then((response) => {
        if (response == null) {
          // TODO show alert
          this.$router.push(this.$router.go(-1))
        } else {
          this.$router.replace({
            name: 'Work',
            params: { id: response.workid, versionId: response.versionid },
          })
        }
      })

      return
    }
  },
}
</script>
